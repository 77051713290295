<template>
    <div>
        <div class="imgItem">
            <div class="imgbox">
                <img class="img" :src="imgUrl">
                <el-checkbox class="gou" :value="status" @change="changeImg" :true-label="id" :false-label="id">
                </el-checkbox>
            </div>
            <div class="sourceword"><span>图片来源：</span>{{itemdata.source}}</div>
            <div class="time"><span>上传时间：</span>{{uploadTime}}</div>

        </div>
    </div>
</template>

<script>
import vue from 'vue'
import { Button, Checkbox, CheckboxGroup } from 'element-ui';
vue.use(Checkbox)
export default {
    name: 'ImgItem',
    model: {
        prop: 'status',
        event: 'change-checked',
    },
    props: {
        itemdata: {
            type: Object
        },
        status: Boolean
    },
    watch: {
        'itemdata.status': function (val) {
            this.status = val
        },
        deep: true,
    },
    data() {
        return {
            id: '',
            imgUrl: '',
            uploadTime: ''
        }
    },
    mounted() {
        this.handlerData()
    },
    methods: {

        changeImg(id, event) {
            this.$emit('change-checked', event.target.checked);
            this.$emit('count-allstatus')
        },

        handlerData() {
            this.imgUrl = this.itemdata.url + "?imageView2/2/w/100/h/100"
            this.uploadTime = this.itemdata.created_at.substr(0, 10)
            this.id = this.itemdata.id
        }
    },
};
</script>

<style lang="scss" scoped>
.imgItem {
    margin: 10px 20px 10px 0;
    width: 150px;
    height: 190px;

    .imgbox {
        height: 150px;
        width: 150px;
        border: solid 1px #efefef;
        border-radius: 4px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }

        .gou {
            position: absolute;
            bottom: 18px;
            right: 18px;
        }
    }

    .sourceword,
    .time {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #848484;
        width: 150px;
    }

    .sourceword {
        text-overflow: ellipsis;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        display: -moz-box;
        -moz-line-clamp: 2;
        -moz-box-orient: vertical;

        overflow-wrap: break-word;
        word-break: break-all;
        white-space: normal;
        overflow: hidden;


    }

}
</style>