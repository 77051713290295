<template>
  <div class="warper">

    <div class="htmltext">
      <div class="text">
        <div class="textcontent" v-html="introData.introduction" ></div>
        <div class="sourcebox">
          <div class="source">
            <span>数据来源：</span>
            <span > {{ introData.source }}</span>
          </div>
        </div>
      </div>
      <div class="pics">

        <span v-for="(pic, k) in introData.pics" :key="pic.id">
          <img-item @count-allstatus="countAllstatus" :itemdata="introData.pics[k]" v-model="introData.pics[k].status">
          </img-item>
        </span>

      </div>
      <div class="action">
        <el-checkbox class="allselect" :value="allselect" @change="selectAll" label="全选"></el-checkbox>

        <download-button :params="params" text="下载选中图片"></download-button>

      </div>

    </div>

  </div>
</template>

<script>
import ImgItem from "./ImgItem.vue";
import vue from "vue";
import { Checkbox, Button } from "element-ui";
import DownloadButton from "@/components/down/DownloadButton.vue"


vue.use(Checkbox);
vue.use(Button);

export default {
  data() {
    return {
      filelist: [],
      allselect: false,
      params: {}
    };
  },
  name: "Htmltext",
  props: {
    introData: Object,
  },
  computed: {

    //当前选择的id
    currentArea: {
      get() {
        return this.$store.state.currentArea
      },
      set(v) {
        this.$store.commit('currentArea', v);
      }
    },

    block: {
      get() {
        return this.$store.state.block

      },
      set(v) {
        this.$store.dispatch('changeBlock', v);
      }
    }



  },
  watch: {

    introData: {
      handler(v) {
        // console.log('====', v.pics)
        const fids = []
        v.pics.map(item => {
          if (item.status) {
            fids.push(item.id)
          }
        })

        let city_plate_id;
        if (this.$route.path === '/blockintro') {
          city_plate_id = this.block
        } else {
          city_plate_id = this.currentArea
        }


        this.params = {
          goods_id: 1,
          condition: {
            file_ids: fids.toString(),
            city_plate_id: city_plate_id
          }
        }

      },
      deep: true
    }

  },

  components: {
    ImgItem,
    DownloadButton
  },
  methods: {
    selectAll(e, event) {
      this.allselect = e;
      this.$emit("selectall", { status: e });
    },

    countAllstatus(e) {
      this.allselect = this.introData.pics.every((item) => {
        return item.status;
      });
    },

    // downloadImageZip() {
    //   //处理选中的数据
    //   this.handleFileList();
    //   if (this.filelist.length === 0) {
    //     this.$message("至少选择一张要下载的图片");
    //   } else {
    //     console.log("filelist", this.filelist);
    //     let file_ids = [];
    //     this.filelist.map((item) => {
    //       file_ids.push(item.file_id);
    //     });
    //     const params = { file_ids: file_ids.toString() };

    //     // this.filesToRar(this.filelist,'城市简介')
    //   }
    // },



    handleFileList() {
      // console.log("this.introData", this.introData);
      let filelist = [];
      this.introData.pics.map((pic) => {
        if (pic.status) {
          const obj = {};
          obj.file_id = pic.id;
          obj.fileUrl = pic.url;
          obj.renameFileName = pic.name || pic.key;
          filelist.push(obj);
        }
      });
      this.filelist = filelist;
    },
  },
};
</script>

<style lang="scss" scoped>
.warper {
  position: relative;
}

.htmltext {
  display: flex;
  flex-direction: column;

  .text {
    color: #333;
    width: 98%;
    background: #f0f3f8;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
  }

  .textcontent {
    text-align: left;
    padding:10px;
    box-sizing: border-box;
   
  }

  .sourcebox {
    text-align: left;
    margin-left: 5px;
    margin-bottom: 5px;

    .source {
      display: inline-block;
      height: 22px;
      line-height: 22px;
      background: rgba(40, 167, 225, 0.1);
      border-radius: 2px;
      border: 1px solid rgba(40, 167, 225, 0.2);
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #28a7e1;
      text-align: left;
      padding: 0 10px;
      
    }
  }

  .pics {
    width: 98%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .action {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding-left: 1%;
    margin-top: 20px;
    margin-bottom: 30px;

    .allselect {
      margin-right: 20px;
    }
  }
}

.source {
  
}



</style>